import { startLoading, endLoading } from "../../actions";
import axios from "axios";
import {
    AUTH_INFO,
    DOMAIN_INFO,
    REDIRECT_URI,
    PROSPECT_INFO,
    USER_SESSION,
    AMBASSADOR_SOCIAL_PROFILE,
    REQUEST_LOADED,
    AUTO_OPEN_CAMPAIGN_ID
} from "../../constants/common";

import { SOCIAL_ACCOUNT_INSTAGRAM } from "../../constants/Types";

let isAnalyticsInitialized = false;

export const receivedAuthInfo = (data) => ({
    type: "RECEIVED_AUTH_INFO",
    payload: data
});

export const receivedTotalSteps = (totalSteps) => ({
    type: "RECEIVED_SIGNUP_TOTAL_STEPS",
    payload: totalSteps
});

export const receivedAuthRequestUrl = (data) => ({
    type: "RECEIVED_AUTH_REQUEST_URL",
    payload: data.result
});

export const receivedAmbassadorAccount = (data) => ({
    type: "RECEIVED_AMBASSADOR_ACCOUNT",
    payload: data.result
});

export const receivedDomainInfo = (data) => ({
    type: "RECEIVED_DOMAIN_INFO",
    payload: data
});

export const ambassadorCreated = (data) => ({
    type: "AMBASSADOR_CREATED",
    payload: data
});

export const fetchProfileInfoCompleted = (result) => ({
    type: "FETCH_PROFILE_COMPLETED",
    payload: {
        ...result,
        state: REQUEST_LOADED
    }
});

export const receivedProspectInfo = (data) => ({
    type: "RECEIVED_PROSPECT_INFO",
    payload: data
});

export const receivedAutoOpenCampaignId = (data) => ({
    type: "RECEIVED_AUTO_OPEN_CAMPAIGN_ID",
    payload: data
});

export const receivedBrandTemplate = (data) => ({
    type: "RECEIVED_BRAND_TEMPLATE",
    payload: data
});

export const fetchBrandTempate = (typeId) => (dispatch) => {
    dispatch(startLoading());
    axios
        .get(`/template/user/bytype`, { params: { typeId } })
        .then((response) => {
            dispatch(receivedBrandTemplate(response.data.result));
            dispatch(endLoading());
        })
        .catch((error) => {
            console.error("Brand Tempate Fetch failed");
            console.error(error);
            dispatch(endLoading());
        });
};

export const fetchDomainInfo = (domain) => (dispatch) => {
    dispatch(startLoading());

    axios({
        method: "GET",
        url: "ambassador/domain",
        allowAnonymous: true,
        params: {
            domain: domain
        }
    })
        .then((response) => {
            if (response && response.data) {
                dispatch(receivedDomainInfo(response.data.result));
                localStorage.setItem(
                    DOMAIN_INFO,
                    JSON.stringify(response.data.result)
                );
            }
            dispatch(endLoading());
        })
        .catch((error) => {
            console.log("Failed getting ambassador domain info");
            console.log(error);
            dispatch(endLoading());
        });
};

export const getAuthRequestUrl = (network, origin) => (dispatch) => {
    dispatch(startLoading());

    let redirectUri = `${REDIRECT_URI}?redirectRoute=${origin}`;

    if (network === SOCIAL_ACCOUNT_INSTAGRAM) {
        redirectUri = REDIRECT_URI;
    }

    axios({
        method: "POST",
        url: `ambassador/social/create-authentication-request`,
        allowAnonymous: true,
        data: {
            network: network,
            redirectUri: redirectUri,
            state: origin
        }
    })
        .then((response) => {
            dispatch(receivedAuthRequestUrl(response.data));
            dispatch(endLoading());
        })
        .catch((error) => {
            console.log("Authorization with Social Network failed");
            console.log(error);
            dispatch(endLoading());
        });
};

export const fetchAmbassadorAccount = () => (dispatch) => {
    dispatch(startLoading());

    axios
        .get(`ambassador/account`)
        .then((response) => {
            dispatch(receivedAmbassadorAccount(response.data));
            dispatch(endLoading());

            let userData =
                response.data && response.data.result
                    ? response.data.result
                    : null;
            const { subscriptions } = userData;
            if (
                process.env.REACT_APP_ENV === "production" &&
                !isAnalyticsInitialized &&
                userData &&
                userData.userId &&
                userData.systemUser &&
                userData.sessionUser &&
                userData.sessionUser.userId > 0
            ) {
                isAnalyticsInitialized = true;

                const storedDomainInfo = JSON.parse(
                    localStorage.getItem(DOMAIN_INFO)
                );

                let userFullName = userData.sessionUser.fullName;
                let role = "Ambassador";

                let domain = storedDomainInfo.domain || null;

                if (userData.businessName) {
                    userFullName += " (" + userData.businessName + ")";
                }

                pendo.initialize({
                    visitor: {
                        id: userData.sessionUser.userId,
                        email: userData.sessionUser.email,
                        role: role,
                        brandDomain: domain,
                        companyName: storedDomainInfo.businessName
                    },

                    account: {
                        id: userData.sessionUser.userId,
                        name: userFullName,
                        creationDate: userData.sessionUser.registrationDate,
                        brandDomain: domain,
                        companyName: storedDomainInfo.businessName
                    }
                });
            }
        })
        .catch((error) => {
            console.log("Fetching ambassador account failed");
            console.log(error);
            dispatch(endLoading());
        });
};

export const upsertAmbassadorSocial = (
    network,
    oauthToken,
    oauthVerifier,
    origin
) => (dispatch) => {
    dispatch(startLoading());

    let redirectUri = `${REDIRECT_URI}?redirectRoute=${origin}`;

    if (network === SOCIAL_ACCOUNT_INSTAGRAM) {
        redirectUri = REDIRECT_URI;
    }

    axios({
        method: "POST",
        url: `ambassador/social-profile`,
        data: {
            networkId: network,
            oauthToken: oauthToken,
            oauthVerifier: oauthVerifier,
            redirectUri: redirectUri,
            state: origin
        }
    })
        .then((response) => {
            dispatch(fetchAmbassadorAccount());
            dispatch(endLoading());
        })
        .catch((error) => {
            console.log("Upserting with Social Network failed");
            console.log(error);
            dispatch(endLoading());
        });
};

export const fetchReferralId = () => (dispatch) => {
    dispatch(startLoading());
    axios
        .get(`/ambassador/referralId`)
        .then((response) => {
            dispatch(receivedReferralId(response.data.result));
            dispatch(endLoading());
        })
        .catch((error) => {
            console.error("ReferralId Fetch failed");
            console.error(error);
            dispatch(endLoading());
        });
};

export const upsertReferralId = (referralId, callback) => (dispatch) => {
    dispatch(startLoading());
    axios
        .post(`/ambassador/referralId/${referralId}`)
        .then((response) => {
            dispatch(fetchReferralId());
            callback(response.data && response.data.message);
            dispatch(endLoading());
        })
        .catch((error) => {
            console.error("ReferralId Fetch failed");
            console.error(error);
            dispatch(endLoading());
        });
};

export const receivedReferralId = (data) => ({
    type: "RECEIVED_REFERRAL_ID",
    payload: data
});

export const setTotalSteps = (totalSteps) => (dispatch) => {
    dispatch(receivedTotalSteps(totalSteps));
};

export const cleanupLocalData = (key) => (dispatch) => {
    switch (key) {
        case AUTH_INFO:
            dispatch(receivedAuthInfo(null));
            break;
        case PROSPECT_INFO:
            dispatch(receivedProspectInfo(null));
            break;
        case AMBASSADOR_SOCIAL_PROFILE:
            dispatch(fetchProfileInfoCompleted(null));
            break;
        case USER_SESSION:
            dispatch(ambassadorCreated(null));
            break;
    }
};

export const restoreState = (key) => (dispatch) => {
    const info = JSON.parse(localStorage.getItem(key));
    if (info) {
        switch (key) {
            case DOMAIN_INFO:
                dispatch(receivedDomainInfo(info));
                break;
            case AUTH_INFO:
                dispatch(receivedAuthInfo(info));
                break;
            case PROSPECT_INFO:
                dispatch(receivedProspectInfo(info));
                break;
            case AMBASSADOR_SOCIAL_PROFILE:
                dispatch(fetchProfileInfoCompleted(info));
                break;
            case AUTO_OPEN_CAMPAIGN_ID:
                dispatch(receivedAutoOpenCampaignId(info));
                break;
        }
    }
};
