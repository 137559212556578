import React, { Component, Fragment } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import { connect } from "react-redux";
import { Spinner } from "react-redux-spinner";
import { CSSTransition } from "react-transition-group";
import ReactGA from "react-ga";

ReactGA.initialize("UA-86926518-1"); // Initialize Google Analytics
import CampaignForm from "./form/CampaignFormContainer";
import Toasts from "./toasts/ToastsContainer";
import { fetchLookupTypes } from "./../actions";
import { USER_SESSION } from "../constants/common";
import NavMenu from "./navigation/NavContainer";

import { loginAmbassador } from "./public/login/actions";
import { setTotalSteps, fetchDomainInfo } from "./public/actions";
import { setCampaignsViewMode, toggleNav } from "./campaigns/actions";
import {
    isChrome,
    isSafari,
    isFirefox,
    isOpera,
    isIE,
    isAndroid,
    isIOS,
    isMobile,
    fullBrowserVersion
} from "react-device-detect";
import UnsubscribeContainer from "./subscription/SubscriptionContainer";

class App extends Component {
    static getBaseLegacyUrl() {
        return process.env.REACT_APP_LEGACY_APP_URL || "";
    }

    constructor() {
        super();
        this.state = {
            domainInfoFetchInProgress: false
        };
    }

    componentDidMount() {
        const { fetchLookupTypes, setTotalSteps } = this.props;
        let lookupTypes = [
            { lookupType: "SOCIAL_ACCOUNT" },
            { lookupType: "CAMPAIGN_REQUIREMENT" }
        ];

        fetchLookupTypes(lookupTypes);
        setTotalSteps(3);
    }

    render() {
        const {
            menuOpen,
            domainInfo,
            ambassadorAccount,
            fetchDomainInfo
        } = this.props;
        const { domainInfoFetchInProgress } = this.state;

        let domainFromUrl = "";

        // Get the domain from the URL (domain in the URL trumps local storage)
        const url = new URL(window.location);
        const urlParts = url.pathname.split("/");

        if (urlParts.length > 1) {
            domainFromUrl = urlParts[1];
        }

        if (
            domainFromUrl &&
            domainFromUrl.toLocaleLowerCase() !== "form" &&
            !domainInfoFetchInProgress
        ) {
            this.setState({ domainInfoFetchInProgress: true });
            fetchDomainInfo(domainFromUrl);
        }

        // Track view changes in Google Analytics (only in production)
        if (
            process.env.REACT_APP_ENV === "production" &&
            window.location &&
            window.location.pathname
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }

        return (
            <Fragment>
                <Router>
                    <div className="App flex-container vertical">
                        <Spinner />
                        <CSSTransition
                            timeout={180}
                            in={!!menuOpen}
                            unmountOnExit
                            mountOnEnter
                            classNames="nav-menu">
                            <NavMenu
                                domainInfo={domainInfo}
                                open={!!menuOpen}
                                userData={ambassadorAccount}
                            />
                        </CSSTransition>
                        <div className="flex-container vertical content-wrapper">
                            <section className="main-view">
                                <Switch>
                                    <Route
                                        path="/logout"
                                        strict
                                        render={() => {
                                            return (
                                                <Redirect
                                                    to={`/${domainFromUrl}/login`}
                                                />
                                            );
                                        }}
                                    />
                                    <Route
                                        path="/form/:token"
                                        strict
                                        component={CampaignForm}
                                    />
                                    <Route
                                        path="/:domain/unsubscribe/:token"
                                        exact
                                        render={(props) => {
                                            try {
                                                localStorage.removeItem(
                                                    USER_SESSION
                                                );
                                            } catch (ex) {
                                                console.error(ex);
                                            }

                                            return (
                                                <UnsubscribeContainer
                                                    {...props}
                                                />
                                            );
                                        }}
                                    />
                                    <Route
                                        path="/:domain/*"
                                        render={() =>
                                            (window.location = `https://roster.link/${domainFromUrl}`)
                                        }
                                    />
                                    <Route
                                        path="/*"
                                        render={() =>
                                            (window.location = `https://roster.link`)
                                        }
                                    />
                                    {/* <Route
                                            path="/:domain/campaigns/:tabName"
                                            strict
                                            // component={CampaignsContainer}
                                            render={(props) => {
                                                const { match } = props;

                                                if (
                                                    campaignsViewMode !==
                                                    match.params.tabName
                                                ) {
                                                    switch (
                                                        match.params.tabName
                                                    ) {
                                                        case CAMPAIGN_VIEW_MODE_NEW:
                                                            setCampaignsViewMode(
                                                                CAMPAIGN_VIEW_MODE_NEW
                                                            );
                                                            break;
                                                        case CAMPAIGN_VIEW_MODE_IN_PROGRESS:
                                                            setCampaignsViewMode(
                                                                CAMPAIGN_VIEW_MODE_IN_PROGRESS
                                                            );
                                                            break;
                                                        case CAMPAIGN_VIEW_MODE_COMPLETE:
                                                            setCampaignsViewMode(
                                                                CAMPAIGN_VIEW_MODE_COMPLETE
                                                            );
                                                            break;
                                                        default:
                                                            setCampaignsViewMode(
                                                                CAMPAIGN_VIEW_MODE_NEW
                                                            );
                                                    }
                                                }

                                                return (
                                                    <CampaignsContainer
                                                        joinedCampaigns
                                                        {...props}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path="/:domain/campaign/:id"
                                            strict
                                            component={CampaignContainer}
                                        />
                                        <Route
                                            path="/campaign/:id/preview"
                                            strict
                                            component={CampaignPreviewContainer}
                                        />
                                        <Route
                                            path="/:domain/login"
                                            strict
                                            render={() =>
                                                (window.location = `https://my.wooly.com/${domain}/login`)
                                            }
                                            // render={(props) => (
                                            //     <LoginContainer
                                            //         {...props}
                                            //         signupUrl={`/${domain}/signup/join`}
                                            //     />
                                            // )}
                                        />
                                        <Route
                                            path="/:domain/signup/profile/connect"
                                            exact
                                            component={SignupConnect}
                                        />
                                        <Route
                                            path="/:domain/signup/terms"
                                            exact
                                            component={SignupTermsAndConditions}
                                        />
                                        <Route
                                            path="/:domain/signup/profile/props/details"
                                            exact
                                            component={
                                                SignupAmbassadorPropertiesContainer
                                            }
                                        />
                                        <Route
                                            path="/:domain/signup/campaign/:token"
                                            exact
                                            component={SignupContainer}
                                        />
                                        <Route
                                            path="/:domain/signup/join"
                                            exact
                                            render={() =>
                                                (window.location = `https://my.wooly.com/${domain}/login`)
                                            }
                                            // render={(props) => (
                                            //     <SignupByNetwork
                                            //         {...props}
                                            //         loginUrl={`/${domain}/login`}
                                            //     />
                                            // )}
                                        />
                                        <Route
                                            path="/:domain/signup/join/:token"
                                            exact
                                            render={(props) => (
                                                <SignupByNetwork
                                                    {...props}
                                                    loginUrl={`/${domain}/login`}
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/:domain/signup/profile"
                                            exact
                                            component={SignupProfile}
                                        />
                                        <Route
                                            path="/:domain/signup"
                                            component={SignupContainer}
                                        />
                                        <Route
                                            path="/:domain/settings"
                                            exact
                                            component={SettingsContainer}
                                        />
                                        <Route
                                            path="/:domain/discounts"
                                            exact
                                            component={DiscountContainer}
                                        />
                                        <Route
                                            path="/:domain/settings/address"
                                            exact
                                            component={AddressContainer}
                                        />
                                        <Route
                                            path="/:domain/settings/email"
                                            exact
                                            component={EmailContainer}
                                        />
                                        <Route
                                            path="/:domain/settings/properties"
                                            exact
                                            component={PropertiesContainer}
                                        />
                                        <Route
                                            render={(props) => {
                                                // Ensure the URL contains the brand's domain
                                                if (
                                                    domainFromUrl &&
                                                    !props.location.search
                                                ) {
                                                    return (
                                                        <Redirect
                                                            to={`/${domainFromUrl}/login`}
                                                        />
                                                    );
                                                } else if (
                                                    !domainFromUrl &&
                                                    domain &&
                                                    !props.location.search
                                                ) {
                                                    return (
                                                        <Redirect
                                                            to={`/${domain}/login`}
                                                        />
                                                    );
                                                } else if (
                                                    !domainFromUrl &&
                                                    !props.location.search
                                                ) {
                                                    return <NoDomainInfo />;
                                                }

                                                if (props.location.search) {
                                                    const queryParams = new URLSearchParams(
                                                        props.location.search
                                                    );

                                                    const state = queryParams.get(
                                                        "state"
                                                    );
                                                    let oauthVerifier = "";
                                                    let oauthToken = "";
                                                    let network = "";

                                                    let redirectRoute = queryParams.get(
                                                        "redirectRoute"
                                                    );

                                                    switch (state) {
                                                        case "Facebook":
                                                            network = SOCIAL_ACCOUNT_FACEBOOK;
                                                            oauthVerifier = queryParams.get(
                                                                "code"
                                                            );
                                                            break;
                                                        default:
                                                            if (state) {
                                                                network = SOCIAL_ACCOUNT_INSTAGRAM;
                                                                oauthVerifier = queryParams.get(
                                                                    "code"
                                                                );

                                                                const stateParts = state.split(
                                                                    "|"
                                                                );

                                                                if (
                                                                    stateParts.length
                                                                ) {
                                                                    redirectRoute =
                                                                        stateParts[1];
                                                                }
                                                            } else {
                                                                oauthVerifier = queryParams.get(
                                                                    "oauth_verifier"
                                                                );
                                                                oauthToken = queryParams.get(
                                                                    "oauth_token"
                                                                );
                                                                network = SOCIAL_ACCOUNT_TWITTER;
                                                            }
                                                            break;
                                                    }

                                                    if (
                                                        redirectRoute &&
                                                        domain
                                                    ) {
                                                        switch (redirectRoute) {
                                                            case REDIRECT_SIGNUP:
                                                                localStorage.setItem(
                                                                    AUTH_INFO,
                                                                    JSON.stringify(
                                                                        {
                                                                            oauthVerifier: oauthVerifier,
                                                                            oauthToken: oauthToken,
                                                                            network: network,
                                                                            domain: domain
                                                                        }
                                                                    )
                                                                );

                                                                return (
                                                                    <SignupContainer
                                                                        {...props}
                                                                    />
                                                                );
                                                            case REDIRECT_SIGNUP_CONNECT:
                                                                upsertAmbassadorSocial(
                                                                    network,
                                                                    oauthToken,
                                                                    oauthVerifier,
                                                                    REDIRECT_SIGNUP_CONNECT
                                                                );
                                                                return (
                                                                    <Redirect
                                                                        to={`/${domain}/signup/profile/connect`}
                                                                        {...props}
                                                                    />
                                                                );
                                                            case REDIRECT_LOGIN:
                                                                loginAmbassador(
                                                                    network,
                                                                    oauthToken,
                                                                    oauthVerifier,
                                                                    REDIRECT_LOGIN,
                                                                    brandUserId,
                                                                    () => {
                                                                        const returnUrl = localStorage.getItem(
                                                                            "returnUrl"
                                                                        );
                                                                        localStorage.removeItem(
                                                                            "returnUrl"
                                                                        );
                                                                        window.location =
                                                                            returnUrl ||
                                                                            `/${domain}/campaigns/new`;
                                                                    }
                                                                );
                                                                return (
                                                                    <Redirect
                                                                        to={`/${domain}/login`}
                                                                        {...props}
                                                                    />
                                                                );
                                                        }
                                                    }
                                                }

                                                return null;
                                            }}
                                        /> */}
                                </Switch>
                            </section>
                        </div>
                        <Toasts />
                    </div>
                </Router>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        lookupTypes,
        navState,
        domainInfo,
        ambassadorAccount,
        campaignsViewMode
    } = state;

    return {
        lookupTypes,
        menuOpen: navState.open,
        domainInfo,
        ambassadorAccount,
        campaignsViewMode
    };
};

const mapDispatchToProps = {
    fetchLookupTypes,
    setTotalSteps,
    toggleNav,
    loginAmbassador,
    setCampaignsViewMode,
    fetchDomainInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
