import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { MdClose, MdSettings, MdFiberNew, MdExitToApp } from "react-icons/md";
import { setCampaignsViewMode, toggleNav } from "../campaigns/actions";
import Img from "react-image";
import InlineSVG from "svg-inline-react";
import Feed from "../../../images/menu/feed.svg";
import Bullhorn from "images/menu/bullhorn.svg";

import PropTypes from "prop-types";
import { findBy } from "./selectors";
import SMFollowers from "../../components/SMFollowers";
import {
    SOCIAL_ACCOUNT_FACEBOOK,
    SOCIAL_ACCOUNT_INSTAGRAM,
    SOCIAL_ACCOUNT_TWITTER
} from "../../constants/Types";
import { NavLink, withRouter } from "react-router-dom";
import {
    CAMPAIGN_VIEW_MODE_IN_PROGRESS,
    CAMPAIGN_VIEW_MODE_NEW
} from "../../constants/common";
import enhanceWithClickOutside from "react-click-outside";

const { bool, func, object } = PropTypes;

class NavMenu extends Component {
    static propTypes = {
        open: bool.isRequired,
        toggleNav: func.isRequired,
        userData: object
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    toggleNav = () => {
        const { toggleNav, open } = this.props;
        toggleNav(!open);
    };

    handleClickOutside() {
        const { toggleNav, open } = this.props;
        if (open) {
            toggleNav(false);
        }
    }

    render() {
        const {
            domainInfo,
            open,
            toggleNav,
            userData,
            setCampaignsViewMode
        } = this.props;

        if (!userData) {
            return null;
        }

        const { defaultImageUri, fullName, socialProfiles } = userData;
        return (
            <div className="nav-container">
                <div className="nav-container-wrapper">
                    <div className="top-bar-wrapper">
                        <button
                            type="button"
                            className="menu-link-icon"
                            onClick={this.toggleNav}>
                            <MdClose />
                        </button>
                    </div>
                    <div className="nav-user-wrapper">
                        <div className="nav-user-avatar-wrapper">
                            <Img
                                className="nav-user-avatar"
                                src={defaultImageUri}
                            />
                        </div>
                        <span className="nav-user-name">{fullName}</span>
                        <div className="social-account-wrapper">
                            <SMFollowers
                                smInfo={findBy(socialProfiles, [
                                    "typeId",
                                    SOCIAL_ACCOUNT_INSTAGRAM
                                ])}
                            />
                            <SMFollowers
                                smInfo={findBy(socialProfiles, [
                                    "typeId",
                                    SOCIAL_ACCOUNT_FACEBOOK
                                ])}
                            />
                            <SMFollowers
                                smInfo={findBy(socialProfiles, [
                                    "typeId",
                                    SOCIAL_ACCOUNT_TWITTER
                                ])}
                            />
                        </div>
                    </div>
                    <div className="divider-horizontal" />
                    <div className="nav-link-container">
                        <NavLink
                            className="nav-link-wrapper"
                            onClick={() => {
                                setCampaignsViewMode(CAMPAIGN_VIEW_MODE_NEW);
                                toggleNav(false);
                            }}
                            to={`/${domainInfo.domain}/campaigns/new`}>
                            <span className="nav-link-text">
                                <span className="nav-link-icon">
                                    <MdFiberNew />
                                </span>
                                New Campaigns
                            </span>
                        </NavLink>
                        <div className="divider-horizontal" />
                        <NavLink
                            className="nav-link-wrapper"
                            onClick={() => {
                                setCampaignsViewMode(
                                    CAMPAIGN_VIEW_MODE_IN_PROGRESS
                                );
                                toggleNav(false);
                            }}
                            to={`/${domainInfo.domain}/campaigns/in-progress`}>
                            <span className="nav-link-text">
                                <span className="nav-link-icon">
                                    <InlineSVG
                                        src={Feed}
                                        className="nav-link-icon"
                                    />
                                </span>
                                My Campaigns
                            </span>
                        </NavLink>
                        <div className="divider-horizontal" />
                        <NavLink
                            className="nav-link-wrapper"
                            to={`/${domainInfo.domain}/discounts`}
                            onClick={() => {
                                toggleNav(false);
                            }}>
                            <span className="nav-link-text">
                                <span className="nav-link-icon">
                                    <InlineSVG
                                        src={Bullhorn}
                                        className="nav-link-icon"
                                    />
                                </span>
                                {`Discounts & Links`}
                            </span>
                        </NavLink>
                        <div className="divider-horizontal" />
                        <NavLink
                            className="nav-link-wrapper"
                            to={`/${domainInfo.domain}/settings`}
                            onClick={() => {
                                toggleNav(false);
                            }}>
                            <span className="nav-link-text">
                                <span className="nav-link-icon">
                                    <MdSettings />
                                </span>
                                Settings
                            </span>
                        </NavLink>
                        <div className="divider-horizontal" />
                    </div>
                    <div>
                        <div className="divider-horizontal" />
                        <NavLink
                            className="nav-link-wrapper"
                            to={`/${domainInfo.domain}/logout`}
                            onClick={() => {
                                toggleNav(false);
                            }}>
                            <span className="nav-link-text">
                                <span className="nav-link-icon">
                                    <MdExitToApp />
                                </span>
                                Logout
                            </span>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    toggleNav,
    setCampaignsViewMode
};

export default withRouter(
    connect(null, mapDispatchToProps)(enhanceWithClickOutside(NavMenu))
);
