import axios from "axios";
import { startLoading, endLoading } from "./../../actions";
import { CAMPAIGN_AUDIENCE_TYPE_AMBASSADOR } from "./../../constants/Types";
import { displayNotification } from "./../../api/actions";

export const setCampaignsViewMode = (viewMode) => ({
    type: "CAMPAIGNS_VIEW_MODE",
    payload: viewMode
});

export const toggleNav = (open) => ({
    type: "TOGGLE_NAV",
    payload: {
        open
    }
});

export const receivedCampaignsInfo = (campaigns) => ({
    type: "RECEIVED_CAMPAIGNS_INFO",
    payload: campaigns.result
});

export const setCampaignBackUrl = (url) => ({
    type: "CAMPAIGN_BACK_URL",
    payload: url
});

export const fetchLookupTypes = (lookupTypes) => (dispatch) => {
    dispatch(startLoading());

    axios({
        method: "POST",
        url: "/lookup/types",
        data: lookupTypes
    })
        .then((response) => {
            dispatch(receivedLookupTypes(response.data));
            dispatch(endLoading());
        })
        .catch((error) => {
            console.log("Lookup types Fetch failed");
            console.log(error);
            dispatch(endLoading());
        });
};

export const clearCampaignsInfo = () => (dispatch) => {
    dispatch({
        type: "RECEIVED_CAMPAIGNS_INFO",
        payload: null
    });
};

export const fetchCampaignsInfo = (filters, callback, scope) => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearCampaignsInfo());

    axios
        .get(`/ambassador/campaigns`)
        .then((response) => {
            dispatch(receivedCampaignsInfo(response.data));
            if (callback && scope) {
                callback.call(scope, response.data.result);
            }
            dispatch(endLoading());
        })
        .catch((error) => {
            console.error("Campaigns Fetch failed");
            console.error(error);
            dispatch(endLoading());
        });
};
