import axios from "axios";
import { startLoading, endLoading } from "../../actions";

export const subscribe = (isSubscribed, token, domain, callback) => (
    dispatch
) => {
    dispatch(startLoading());

    axios({
        method: "POST",
        url: "/account/subscribe/email",
        allowAnonymous: true,
        data: { token, domain, isSubscribed }
    })
        .then((response) => {
            dispatch(endLoading());
            if (callback) {
                callback();
            }
        })
        .catch((error) => {
            console.log("Subscription failed");
            console.log(error);
            dispatch(endLoading());
        });
};
