export const campaignInfoReducer = (state = {}, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_CAMPAIGN_INFO":
            return payload;
        default:
            return state;
    }
};

export const campaignJoinedStatusReducer = (state = {}, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_JOIN_RESULT":
            return payload;
        default:
            return state;
    }
};

export const canJoinCampaignReducer = (state = {}, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_JOIN_VALIDATION_RESULT":
            return payload;
        default:
            return state;
    }
};

export const campaignPropertiesReducer = (state = [], action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_CAMPAIGN_PROPERTIES":
            return payload;
        case "CLEAR_CAMPAIGN_PROPERTIES":
            return [];
        default:
            return state;
    }
};
