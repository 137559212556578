export const collaborationKeyReducer = (state = null, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_COLLABORATION_KEY":
            return payload;
        default:
            return state;
    }
};

export default collaborationKeyReducer;
