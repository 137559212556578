import { USER_SESSION } from "../../constants/common";
import { cleanupLocalData } from "./actions";

export const authInfoReducer = (state = {}, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_AUTH_INFO":
            return payload;
        default:
            return state;
    }
};

export const signupTotalStepsReducer = (state = 0, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_SIGNUP_TOTAL_STEPS":
            return payload;
        default:
            return state;
    }
};

export const authRequestUrlReducer = (state = null, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_AUTH_REQUEST_URL":
            return payload;
        default:
            return state;
    }
};

export const ambassadorAccountReducer = (state = null, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_AMBASSADOR_ACCOUNT":
            return payload;
        default:
            return state;
    }
};

export const ambassadorReferralId = (state = null, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_REFERRAL_ID":
            return payload;
        default:
            return state;
    }
};

export const domainInfoReducer = (state = null, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_DOMAIN_INFO":
            const userSession = localStorage.getItem(USER_SESSION);
            if (userSession) {
                const accessToUserId = JSON.parse(userSession).accessToUserId;
                if (accessToUserId !== payload.brandUserId) {
                    localStorage.removeItem(USER_SESSION);
                    cleanupLocalData(USER_SESSION);
                    window.location = `${process.env.REACT_APP_URL}${payload.domain}/login`;
                }
            }
            return payload;
        default:
            return state;
    }
};

export const autoOpenCampaignIdReducer = (state = 0, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_AUTO_OPEN_CAMPAIGN_ID":
            return payload;
        default:
            return state;
    }
};

export const brandTemplateReducer = (state = {}, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_BRAND_TEMPLATE":
            return payload;
        default:
            return state;
    }
};
