import {
    FaTwitter as TwitterIcon,
    FaLinkedin as LinkedInIcon,
    FaFacebook as FacebookIcon,
    FaPinterestSquare as PinterestIcon
} from "react-icons/fa";
import YouTubeIcon from "../components/icons/YouTubeIcon";
import InstagramIcon from "../components/icons/InstagramIcon";
import {
    MdFileUpload as FileUploadIcon,
    MdLink as WebLinkIcn
} from "react-icons/md";
import { SiTiktok } from "react-icons/si";

import {
    SOCIAL_ACCOUNT_TWITTER,
    SOCIAL_ACCOUNT_INSTAGRAM,
    SOCIAL_ACCOUNT_LINKEDIN,
    SOCIAL_ACCOUNT_FACEBOOK,
    SOCIAL_ACCOUNT_YOUTUBE,
    SOCIAL_LISTENING_FACEBOOK,
    SOCIAL_LISTENING_INSTAGRAM,
    SOCIAL_ACCOUNT_PINTEREST,
    SOCIAL_ACCOUNT_TIKTOK
} from "./SocialMedias";
import {
    CAMPAIGN_REQUIREMENT_TWITTER,
    CAMPAIGN_REQUIREMENT_FACEBOOK,
    CAMPAIGN_REQUIREMENT_INSTAGRAM_POST,
    CAMPAIGN_REQUIREMENT_INSTAGRAM_STORY,
    CAMPAIGN_REQUIREMENT_UPLOAD_CONTENT,
    CAMPAIGN_REQUIREMENT_WEB_LINK
} from "../constants/Types";

export default {
    [SOCIAL_ACCOUNT_FACEBOOK]: {
        smId: SOCIAL_ACCOUNT_FACEBOOK,
        icon: FacebookIcon,
        color: "#3b5998"
    },
    [SOCIAL_ACCOUNT_PINTEREST]: {
        smId: SOCIAL_ACCOUNT_PINTEREST,
        icon: PinterestIcon,
        color: "#3b5998"
    },
    [SOCIAL_LISTENING_FACEBOOK]: {
        smId: SOCIAL_LISTENING_FACEBOOK,
        icon: FacebookIcon,
        color: "#3b5998"
    },
    [SOCIAL_ACCOUNT_INSTAGRAM]: {
        smId: SOCIAL_ACCOUNT_INSTAGRAM,
        icon: InstagramIcon,
        color: "#c83385"
    },
    [SOCIAL_LISTENING_INSTAGRAM]: {
        smId: SOCIAL_LISTENING_INSTAGRAM,
        icon: InstagramIcon,
        color: "#c83385"
    },
    [SOCIAL_ACCOUNT_LINKEDIN]: {
        smId: SOCIAL_ACCOUNT_LINKEDIN,
        icon: LinkedInIcon,
        color: "#007bb5"
    },
    [SOCIAL_ACCOUNT_TWITTER]: {
        smId: SOCIAL_ACCOUNT_TWITTER,
        icon: TwitterIcon,
        color: "#55acee"
    },
    [SOCIAL_ACCOUNT_YOUTUBE]: {
        smId: SOCIAL_ACCOUNT_YOUTUBE,
        icon: YouTubeIcon,
        color: "#bb0000"
    },
    [SOCIAL_ACCOUNT_TIKTOK]: {
        smId: SOCIAL_ACCOUNT_TIKTOK,
        icon: SiTiktok,
        color: "#007bb5"
    },
    [CAMPAIGN_REQUIREMENT_TWITTER]: { icon: TwitterIcon, color: "#415f66" },
    [CAMPAIGN_REQUIREMENT_FACEBOOK]: { icon: FacebookIcon, color: "#415f66" },
    [CAMPAIGN_REQUIREMENT_INSTAGRAM_POST]: {
        icon: InstagramIcon,
        color: "#415f66"
    },
    [CAMPAIGN_REQUIREMENT_INSTAGRAM_STORY]: {
        icon: InstagramIcon,
        color: "#415f66"
    },
    [CAMPAIGN_REQUIREMENT_UPLOAD_CONTENT]: {
        icon: FileUploadIcon,
        color: "#415f66"
    },
    [CAMPAIGN_REQUIREMENT_WEB_LINK]: {
        icon: WebLinkIcn,
        color: "#415f66"
    }
};
