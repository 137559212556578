import { pendingTask, begin, end } from "react-redux-spinner";
import axios from "axios";

export const startLoading = () => ({
    type: "START_LOADING",
    [pendingTask]: begin
});

export const endLoading = () => ({
    type: "END_LOADING",
    [pendingTask]: end
});

export const toggleNav = (open) => ({
    type: "TOGGLE_NAV",
    payload: {
        open
    }
});

export const receivedLookupTypes = (types) => ({
    type: "RECEIVED_LOOKUP_TYPES",
    payload: types.result
});

export const receivedAmbassadorProperties = (properties) => ({
    type: "RECEIVED_AMBASSADOR_PROPERTIES",
    payload: properties.result
});

export const receivedCountries = (response) => ({
    type: "RECEIVED_COUNTRIES",
    payload: response.result
});

export const clearAmbassadorProperties = () => ({
    type: "CLEAR_AMBASSADOR_PROPERTIES",
    payload: null
});

export const setConnectConfig = (cfg) => ({
    type: "CONNECT_CONFIG",
    payload: cfg
});

export const clearConnectConfig = () => ({
    type: "CONNECT_CONFIG",
    payload: null
});

export const fetchLookupTypes = (lookupTypes) => (dispatch) => {
    dispatch(startLoading());

    axios({
        method: "POST",
        url: "/lookup/types-anonymous",
        data: lookupTypes,
        allowAnonymous: true
    })
        .then((response) => {
            dispatch(receivedLookupTypes(response.data));
            dispatch(endLoading());
        })
        .catch((error) => {
            console.log("Lookup types fetch failed");
            console.log(error);
            dispatch(endLoading());
        });
};

export const fetchAmbassadorProperties = (groupTypeId, campaignId) => (
    dispatch
) => {
    dispatch(startLoading());
    axios
        .get(`/ambassador/properties`, { params: { groupTypeId, campaignId } })
        .then((response) => {
            dispatch(receivedAmbassadorProperties(response.data));
            dispatch(endLoading());
        })
        .catch((error) => {
            console.error("Ambassador Properties Fetch failed");
            console.error(error);
            dispatch(endLoading());
        });
};

export const upsertAmbassadorProperties = (properties) => (dispatch) => {
    dispatch(startLoading());
    axios
        .post(`/ambassador/properties`, properties)
        .then((response) => {
            dispatch(endLoading());
        })
        .catch((error) => {
            console.error("Ambassador Properties Fetch failed");
            console.error(error);
            dispatch(endLoading());
        });
};

export const fetchCountries = (userId) => (dispatch) => {
    dispatch(startLoading());
    axios
        .get(`/common/countries?userId=${userId}`, {
            allowAnonymous: true
        })
        .then((response) => {
            dispatch(receivedCountries(response.data));
            dispatch(endLoading());
        })
        .catch((error) => {
            console.error("Countries Fetch failed");
            console.error(error);
            dispatch(endLoading());
        });
};
