import { startLoading, endLoading } from "../../../actions";
import axios from "axios";
import {
    USER_SESSION,
    REDIRECT_URI,
    PROSPECT_INFO,
    AUTH_INFO,
    AMBASSADOR_SOCIAL_PROFILE
} from "../../../constants/common";
import { SOCIAL_ACCOUNT_INSTAGRAM } from "../../../constants/Types";
import { displayNotification } from "../../../api/actions";
import { cleanupLocalData } from "../actions";

export const loginAmbassador = (
    network,
    oauthToken,
    oauthVerifier,
    origin,
    brandUserId,
    callback
) => (dispatch) => {
    dispatch(startLoading());

    let redirectUri = `${REDIRECT_URI}?redirectRoute=${origin}`;

    if (network === SOCIAL_ACCOUNT_INSTAGRAM) {
        redirectUri = REDIRECT_URI;
    }

    axios({
        method: "POST",
        url: `ambassador/login`,
        allowAnonymous: true,
        data: {
            networkId: network,
            brandUserId: brandUserId,
            oauthToken: oauthToken,
            oauthVerifier: oauthVerifier,
            redirectUri: redirectUri
        }
    })
        .then((response) => {
            localStorage.setItem(
                USER_SESSION,
                JSON.stringify(response.data.result)
            );

            localStorage.removeItem(AUTH_INFO);
            localStorage.removeItem(AMBASSADOR_SOCIAL_PROFILE);

            dispatch(cleanupLocalData(AUTH_INFO));
            dispatch(cleanupLocalData(AMBASSADOR_SOCIAL_PROFILE));

            if (callback) {
                callback();
            }
            dispatch(endLoading());
        })
        .catch((error) => {
            if (error.data) {
                dispatch(
                    displayNotification({
                        text: error.data.message,
                        type: "error",
                        autohide: false
                    })
                );
            }
            console.log("Login with Social Network failed");
            console.log(error);
            dispatch(endLoading());
        });
};
