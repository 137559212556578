import { combineReducers } from "redux";
import { pendingTasksReducer as pendingTasks } from "react-redux-spinner";
import collaborationKeyReducer from "./containers/form/collaborationInfoReducer";
import {
    prospectInfoReducer,
    socialProfileInfoReducer
} from "./containers/public/signup/signupReducer";
import {
    ambassadorAccountReducer,
    signupTotalStepsReducer,
    authInfoReducer,
    authRequestUrlReducer,
    domainInfoReducer,
    autoOpenCampaignIdReducer,
    brandTemplateReducer,
    ambassadorReferralId
} from "./containers/public/reducer";
import {
    campaignInfoReducer,
    campaignJoinedStatusReducer,
    campaignPropertiesReducer,
    canJoinCampaignReducer
} from "./containers/campaigns/campaign/reducer";
import {
    navReducer,
    userCampaignsReducer,
    userCampaignsViewModeReducer,
    userCampaignBackUrlReducer
} from "./containers/campaigns/reducer";
import {
    ambassadorAddressesReducer,
    ambassadorPropertiesReducer
} from "./containers/settings/reducer";
import { ambassadorDiscountsReducer } from "./containers/discounts/reducer";

export const lookupTypesReducer = (state = [], action) => {
    const payload = action.payload;

    switch (action.type) {
        case "RECEIVED_LOOKUP_TYPES":
            return payload;
        default:
            return state;
    }
};

const notificationsReducer = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case "INFO_MESSAGE":
            return [
                ...state,
                {
                    ...payload,
                    id: Date.now()
                }
            ];
        case "REMOVE_INFO_MESSAGE":
            return state.filter((message) => {
                return message.id === payload.infoId;
            });
        default:
            return state;
    }
};

export const connectConfigReducer = (state = {}, action) => {
    const payload = action.payload;
    const defaultCfg = {
        submitBtnText: "Next",
        url: "/signup/terms",
        stepProgress: true
    };

    switch (action.type) {
        case "CONNECT_CONFIG":
            return payload || defaultCfg;
        default:
            return state;
    }
};

export const countriesReducer = (state = [], action) => {
    const payload = action.payload;

    switch (action.type) {
        case "RECEIVED_COUNTRIES":
            return payload || [];
        default:
            return state;
    }
};

export default combineReducers({
    pendingTasks,
    infoNotifications: notificationsReducer,
    lookupTypes: lookupTypesReducer,
    collaborationKey: collaborationKeyReducer,
    prospectInfo: prospectInfoReducer,
    authRequestUrl: authRequestUrlReducer,
    authInfo: authInfoReducer,
    ambassadorSocialProfile: socialProfileInfoReducer,
    navState: navReducer,
    totalSteps: signupTotalStepsReducer,
    ambassadorAccount: ambassadorAccountReducer,
    domainInfo: domainInfoReducer,
    campaignInfo: campaignInfoReducer,
    userCampaigns: userCampaignsReducer,
    campaignsViewMode: userCampaignsViewModeReducer,
    campaignJoinedStatus: campaignJoinedStatusReducer,
    campaignProperties: campaignPropertiesReducer,
    canJoinCampaign: canJoinCampaignReducer,
    ambassadorAddresses: ambassadorAddressesReducer,
    ambassadorProperties: ambassadorPropertiesReducer,
    connectConfig: connectConfigReducer,
    countries: countriesReducer,
    autoOpenCampaignId: autoOpenCampaignIdReducer,
    brandTemplate: brandTemplateReducer,
    campaignBackUrl: userCampaignBackUrlReducer,
    ambassadorDiscounts: ambassadorDiscountsReducer,
    referralId: ambassadorReferralId
});
