import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCollaborationKey } from "./actions";

class CampaignFormContainer extends Component {
    constructor() {
        super();

        this.state = {
            containerSize: {
                requested: false,
                sent: false
            }
        };

        window.addEventListener(
            "message",
            (event) => {
                const { data } = event;
                if (data === "size") {
                    this.setState((prevState) => {
                        return {
                            containerSize: {
                                ...prevState.containerSize,
                                requested: true
                            }
                        };
                    });
                }
            },
            false
        );
    }

    componentDidMount() {
        const { fetchCollaborationKey, match } = this.props;
        const {
            params: { token }
        } = match;
        fetchCollaborationKey(token);

        window.parent.postMessage(
            {
                height: 88,
                size: {
                    height: 88
                },
                type: "resize"
            },
            "*"
        );
    }

    opeNewForm = () => {
        const { collaborationKey } = this.props;

        window.open(
            `//${process.env.REACT_APP_RECRUITMENT_FORM_URL}${collaborationKey}`,
            "_blank"
        );
    };

    render() {
        const { collaborationKey } = this.props;

        return (
            <div className="default-container">
                <div className="button-wrapper">
                    <button
                        type="button"
                        role="link"
                        disabled={!collaborationKey}
                        onClick={this.opeNewForm}>
                        Apply here
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { collaborationKey } = state;

    return {
        collaborationKey
    };
};

const mapDispatchToProps = {
    fetchCollaborationKey
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignFormContainer);
