import React, { Component } from "react";
import IconMap from "./../constants/SMIcons";
import PropTypes from "prop-types";
import { shortNumber } from "../common/util";
const { bool, func, object, oneOf } = PropTypes;

export default class SMFollowers extends Component {
    static propTypes = {
        smInfo: object
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { smInfo } = this.props;
        if (!smInfo) {
            return null;
        }

        return (
            <span className="sm-followers">
                <span className="sm-icon">
                    {React.createElement(IconMap[smInfo.typeId].icon, {
                        className: "icon"
                    })}
                </span>
                <span className="sm-followers-count">
                    {shortNumber(smInfo.stats.followedByCount)}
                </span>
            </span>
        );
    }
}
