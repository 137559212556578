import { REQUEST_READY } from "../../../constants/common";

export const prospectInfoReducer = (state = null, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "RECEIVED_PROSPECT_INFO":
            return payload;
        default:
            return state;
    }
};

export const socialProfileInfoReducer = (
    state = { state: REQUEST_READY },
    action
) => {
    const payload = action.payload;
    switch (action.type) {
        case "FETCH_PROFILE_COMPLETED":
        case "FETCH_PROFILE_LOADING":
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
};
