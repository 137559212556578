export const ambassadorAddressesReducer = (state = [], action) => {
    const payload = action.payload;

    switch (action.type) {
        case "RECEIVED_AMBASSADOR_ADDRESSES":
            return payload || [];
        case "CLEAR_AMBASSADOR_ADDRESSES":
            return [];
        default:
            return state;
    }
};

export const ambassadorPropertiesReducer = (state = [], action) => {
    const payload = action.payload;

    switch (action.type) {
        case "RECEIVED_AMBASSADOR_PROPERTIES":
            return payload || [];
        case "CLEAR_AMBASSADOR_PROPERTIES":
            return [];
        default:
            return state;
    }
};
