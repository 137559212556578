export const REDIRECT_URI = `${process.env.REACT_APP_URL}`;

// Request loading state
export const REQUEST_READY = 0;
export const REQUEST_LOADING = 1;
export const REQUEST_LOADED = 2;

// Local Storage Keys
export const PROSPECT_INFO = "PROSPECT_INFO";
export const DOMAIN_INFO = "DOMAIN_INFO";
export const AUTH_INFO = "AUTH_INFO";
export const AMBASSADOR_SOCIAL_PROFILE = "AMBASSADOR_SOCIAL_PROFILE";
export const USER_SESSION = "userSession";
export const INVITE_TOKEN = "INVITE_TOKEN";
export const AUTO_OPEN_CAMPAIGN_ID = "AUTO_OPEN_CAMPAIGN_ID";
export const CONNECT_CONFIG = "CONNECT_CONFIG";
export const PREVIEW_ACCESS_TOKEN = "PREVIEW_ACCESS_TOKEN";

// Redirect keys
export const REDIRECT_SIGNUP = "signup";
export const REDIRECT_SIGNUP_CONNECT = "signup-connect";
export const REDIRECT_LOGIN = "login";

export const CAMPAIGN_VIEW_MODE_NEW = "new";
export const CAMPAIGN_VIEW_MODE_IN_PROGRESS = "in-progress";
export const CAMPAIGN_VIEW_MODE_COMPLETE = "complete";

// Back End error types and levels
export const RESPONSE_ERROR_LEVEL_NONE = 0;
export const RESPONSE_ERROR_LEVEL_HIGH = 1;
export const RESPONSE_ERROR_LEVEL_MEDIUM = 2;
export const RESPONSE_ERROR_LEVEL_LOW = 3;

export const RESPONSE_ERROR_TYPE_NONE = 0;
export const RESPONSE_ERROR_TYPE_EXCEPTION = 1;
export const RESPONSE_ERROR_TYPE_WARNING = 2;
export const RESPONSE_ERROR_TYPE_VERBOSE = 3;
export const RESPONSE_ERROR_TYPE_VALIDATION = 4;
