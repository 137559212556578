import { CAMPAIGN_VIEW_MODE_NEW } from "../../constants/common";

export const navReducer = (state = {}, action) => {
    const payload = action.payload;
    switch (action.type) {
        case "TOGGLE_NAV":
            return payload;
        default:
            return state;
    }
};
export const userCampaignsReducer = (state = [], action) => {
    const payload = action.payload;

    switch (action.type) {
        case "RECEIVED_CAMPAIGNS_INFO":
            return payload || [];
        default:
            return state;
    }
};

export const userCampaignsViewModeReducer = (
    state = CAMPAIGN_VIEW_MODE_NEW,
    action
) => {
    const payload = action.payload;

    switch (action.type) {
        case "CAMPAIGNS_VIEW_MODE":
            return payload;
        default:
            return state;
    }
};

export const userCampaignBackUrlReducer = (state = null, action) => {
    const payload = action.payload;

    switch (action.type) {
        case "CAMPAIGN_BACK_URL":
            return payload;
        default:
            return state;
    }
};
