import { addMinutes } from "date-fns";

export function fromUTC(date) {
    const now = new Date(date);
    const offset = now.getTimezoneOffset();
    return addMinutes(date, -offset);
}

export function toUTC(date) {
    const now = new Date(date);
    const offset = now.getTimezoneOffset();
    return addMinutes(date, offset);
}

export const shortNumber = (number) => {
    if (!number || number <= 0) {
        return 0;
    } else {
        const abs = Math.abs(number);
        if (abs >= Math.pow(10, 12)) {
            // trillion
            number = (number / Math.pow(10, 12)).toFixed(1) + "T";
        } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) {
            // billion
            number = (number / Math.pow(10, 9)).toFixed(1) + "B";
        } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) {
            // million
            number = (number / Math.pow(10, 6)).toFixed(1) + "M";
        } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 3)) {
            // thousand
            number = (number / Math.pow(10, 3)).toFixed(1) + "K";
        }
        return number;
    }
};
