import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import Toast from "../../common/Toast";
import { dismissNotification } from "../../api/actions";

const Toasts = ({ dismissNotification, toasts }) => {
    return (
        <ul className="toasts">
            {toasts.map((toast) => {
                const { id, onDismiss } = toast;
                return (
                    <Toast
                        {...toast}
                        key={id}
                        onDismiss={() => {
                            if (onDismiss) {
                                onDismiss();
                            }
                            dismissNotification(id);
                        }}
                    />
                );
            })}
        </ul>
    );
};

Toasts.propTypes = {
    dismissNotification: PropTypes.func.isRequired,
    toasts: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapDispatchToProps = {
    dismissNotification
};

const mapStateToProps = (state) => ({
    toasts: state.infoNotifications
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
