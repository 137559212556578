import axios from "axios";
import {
    USER_SESSION,
    DOMAIN_INFO,
    PREVIEW_ACCESS_TOKEN
} from "../constants/common";
import qs from "qs";

export const setupDAL = function(dispatch) {
    axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

    axios.defaults.paramsSerializer = (params) => {
        return qs.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true
        });
    };

    axios.interceptors.request.use(
        function(req) {
            if (req.allowAnonymous) {
                req.headers = {
                    Authorization:
                        // TODO: get from environment config. (not working)
                        "clientKey e6Ys9wezVp7OQfcuQAjQjES4YAYs3Q" +
                        "| clientSecret Uqa9FQpmERXUVy23ZOlEIg26xLQK3o"
                };
            } else {
                let previewAccessToken;
                try {
                    previewAccessToken = localStorage.getItem(
                        PREVIEW_ACCESS_TOKEN
                    );
                } catch (e) {}
                if (req.headers.previewMode && previewAccessToken) {
                    req.headers = {
                        Authorization: "Bearer " + previewAccessToken
                    };
                    return req;
                }

                let domain;
                try {
                    domain = JSON.parse(localStorage.getItem(DOMAIN_INFO))
                        .domain;
                } catch (e) {}

                let accessToken;
                try {
                    accessToken = JSON.parse(localStorage.getItem(USER_SESSION))
                        .accessToken;
                } catch (e) {}

                if (!accessToken) {
                    const url = new URL(window.location);
                    const urlParts = url.pathname.split("/");
                    let domainFromUrl;
                    if (urlParts.length > 1) {
                        domainFromUrl = urlParts[1];
                    }

                    if (domain || domainFromUrl) {
                        window.location = `${process.env.REACT_APP_URL}${
                            domainFromUrl ? domainFromUrl : domain
                        }/login`;
                    } else {
                        window.location = `${process.env.REACT_APP_URL}login`;
                    }
                } else {
                    req.headers = {
                        Authorization: "Bearer " + accessToken
                    };
                }
            }

            return req;
        },
        function(error) {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        function(response) {
            // Do something with response data
            if (response.data.success === false) {
                if (
                    response.data &&
                    response.data.result &&
                    response.data.result.action === "redirect"
                ) {
                    window.location = response.data.result.value;
                } else {
                    dispatch({
                        type: "API_ERROR",
                        payload: response.data.message
                    });
                }

                return Promise.reject(response);
            }
            return response;
        },
        function(error) {
            // Do something with response error
            if (error.response && error.response.status === 401) {
                localStorage.setItem("returnUrl", window.location.href);
                let { domain } = JSON.parse(localStorage.getItem(DOMAIN_INFO));

                if (!domain) {
                    const url = new URL(window.location);
                    const urlParts = url.pathname.split("/");

                    if (urlParts.length > 1) {
                        domain = urlParts[1];
                    }
                }

                if (domain) {
                    window.location.href = `${process.env.REACT_APP_URL}${domain}/login`;
                }
            }

            dispatch({
                type: "API_ERROR",
                payload: error.response.data.message
            });
            return Promise.reject(error);
        }
    );
};

export const request = axios;
