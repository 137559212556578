import React from "react";
import ReactDOM from "react-dom";
import "../styles/App.scss";
import App from "./containers/App";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { setupDAL } from "./api";

// window.Intercom("boot", {
//     app_id: "d6866gvd"
// });

let store = configureStore();

let dispatch = store.dispatch;

setupDAL(dispatch);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);
