/* eslint-disable */
export const SOCIAL_ACCOUNT_TWITTER = 9;
export const SOCIAL_ACCOUNT_INSTAGRAM = 10;
export const SOCIAL_ACCOUNT_FACEBOOK = 11;
export const SOCIAL_ACCOUNT_YOUTUBE = 20;
export const SOCIAL_ACCOUNT_GOOGLE = 21;
export const SOCIAL_ACCOUNT_PINTEREST = 22;
export const SOCIAL_ACCOUNT_LINKEDIN = 23;
export const SOCIAL_LISTENING_FACEBOOK = 183;
export const SOCIAL_LISTENING_INSTAGRAM = 184;
export const SOCIAL_ACCOUNT_DEFAULT = 0;
export const SOCIAL_ACCOUNT_TIKTOK = 599;
