import React, { Component } from "react";
import { connect } from "react-redux";
import { subscribe } from "./actions";

class SubscriptionContainer extends Component {
    constructor() {
        super();

        this.state = {
            subscribed: false
        };
    }
    componentDidMount() {
        this.unsubscribe();
    }

    unsubscribe = () => {
        const {
            match: {
                params: { domain, token }
            },
            subscribe
        } = this.props;
        if (token && domain) {
            subscribe(false, token, domain);
        }
    };

    subscribe = () => {
        const {
            match: {
                params: { domain, token }
            },
            subscribe
        } = this.props;
        if (token && domain) {
            subscribe(true, token, domain, () => {
                this.setState({ subscribed: true });
            });
        }
    };

    render() {
        const { domainInfo } = this.props;
        const { subscribed } = this.state;

        let message = "Please wait...";

        if (domainInfo) {
            const { businessName } = domainInfo;
            if (subscribed) {
                message = <h1>Subscribe Successful</h1>;
            } else {
                message = (
                    <>
                        <h1>Unsubscribe Successful</h1>
                        You have been successfully unsubscribed from emails sent
                        within the Wooly platform for {businessName}.<br />
                        <br />
                        If you would like to re-subscribe please click the
                        button below.
                        <button
                            type="button"
                            onClick={this.subscribe}
                            className="submit-btn small-btn btn-theme-primary accept-btn">
                            Subscribe
                        </button>
                    </>
                );
            }
        }

        return (
            <div className="subscription-container">
                <div className="message">{message}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { domainInfo } = state;
    return { domainInfo };
};

const mapDispatchToProps = { subscribe };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriptionContainer);
