import { startLoading, endLoading } from "../../actions";
import axios from "axios";

export const receivedCollaborationKey = (collaborationInfo) => ({
    type: "RECEIVED_COLLABORATION_KEY",
    payload: collaborationInfo.result
});

export const fetchCollaborationKey = (token) => (dispatch) => {
    dispatch(startLoading());

    axios({
        method: "GET",
        url: `collaborate/campaign`,
        allowAnonymous: true,
        params: {
            token: token
        }
    })
        .then((response) => {
            dispatch(receivedCollaborationKey(response.data));
            dispatch(endLoading());
        })
        .catch((error) => {
            console.log("Collaboration info fetch failed");
            console.log(error);
            dispatch(endLoading());
        });
};
