import PropTypes from "prop-types";
import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import {
    MdInfoOutline as InfoOutlineIcon,
    MdCheck as CheckIcon,
    MdWarning as WarningIcon,
    MdErrorOutline as ErrorOutlineIcon,
    MdClose as CloseIcon
} from "react-icons/md";
import { Interweave } from "interweave";

const autohideTimeout = 5000;

class Toast extends Component {
    constructor(...args) {
        super(...args);

        const { autohide, onDismiss, id } = this.props;
        this.state = { show: true };

        if (autohide) {
            setTimeout(() => {
                let { show } = this.state;
                this.setState({ show: !show });
                // onDismiss(id);
            }, autohideTimeout);
        }
    }

    componentWillUnmount() {
        this.setState({ show: false });
    }

    getIcon(type) {
        switch (type) {
            case "info":
                return (
                    <i className="toast__icon material-icons">
                        <InfoOutlineIcon />
                    </i>
                );
            case "success":
                return (
                    <i className="toast__icon material-icons">
                        <CheckIcon />
                    </i>
                );
            case "warning":
                return (
                    <i className="toast__icon material-icons">
                        <WarningIcon />
                    </i>
                );
            case "error":
                return (
                    <i className="toast__icon material-icons">
                        <ErrorOutlineIcon />
                    </i>
                );
        }
    }

    render() {
        const { type, text, onDismiss, id } = this.props;
        const { show } = this.state;
        return (
            <CSSTransition
                in={show}
                timeout={400}
                mountOnEnter
                onExited={() => {
                    onDismiss(id);
                }}
                classNames="fade">
                <div className="toast-wrapper" key={id}>
                    <li className={`toast ${type}`}>
                        {this.getIcon(type)}
                        <p className="toast__content">
                            <Interweave
                                className="toast_sub-title"
                                content={text}
                            />
                        </p>
                        <button
                            type="submit"
                            style={{ color: "white" }}
                            className="toast__dismiss"
                            icon
                            primary
                            onClick={onDismiss}>
                            <CloseIcon />
                        </button>
                    </li>
                </div>
            </CSSTransition>
        );
    }
}

Toast.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onDismiss: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    autohide: PropTypes.bool.isRequired
};

export default Toast;
