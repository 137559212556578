// Social Network Types
export const SOCIAL_ACCOUNT_TWITTER = 9;
export const SOCIAL_ACCOUNT_INSTAGRAM = 10;
export const SOCIAL_ACCOUNT_FACEBOOK = 11;
export const SOCIAL_ACCOUNT_YOUTUBE = 20;
export const SOCIAL_ACCOUNT_GOOGLE = 21;
export const SOCIAL_ACCOUNT_PINTEREST = 22;
export const SOCIAL_ACCOUNT_LINKEDIN = 23;

// Campaign Statuses
export const CAMPAIGN_STATUS_DRAFT = 146;
export const CAMPAIGN_STATUS_ACTIVE = 147;
export const CAMPAIGN_STATUS_ENDED = 148;
export const CAMPAIGN_STATUS_DELETED = 199;
export const CAMPAIGN_STATUS_PLANNED = 226;

// Campaign Requirements
export const CAMPAIGN_REQUIREMENT_INSTAGRAM_POST = 235;
export const CAMPAIGN_REQUIREMENT_INSTAGRAM_STORY = 236;
export const CAMPAIGN_REQUIREMENT_FACEBOOK = 237;
export const CAMPAIGN_REQUIREMENT_TWITTER = 238;
export const CAMPAIGN_REQUIREMENT_UPLOAD_CONTENT = 284;
export const CAMPAIGN_REQUIREMENT_SIGNED_UP = 290;
export const CAMPAIGN_REQUIREMENT_WEB_LINK = 444;

export const CAMPAIGN_AUDIENCE_TYPE_AMBASSADOR = 225;

export const SOCIAL_PROFILE_TYPE_PERSONAL = 260;
export const SOCIAL_PROFILE_TYPE_BUSINESS = 261;

export const USER_PROPERTY_TYPE_TEXTBOX = 277;
export const USER_PROPERTY_TYPE_TEXTAREA = 278;
export const USER_PROPERTY_TYPE_DROPDOWN = 279;
export const USER_PROPERTY_TYPE_CHECKBOX = 280;
export const USER_PROPERTY_TYPE_DATE = 295;
export const USER_PROPERTY_TYPE_RADIO = 296;
export const USER_PROPERTY_TYPE_FILE = 588;

export const USER_PROPERTY_GROUP_AMBASSADOR_PROFILE = 281;
export const USER_PROPERTY_GROUP_CAMPAIGN = 282;
export const USER_PROPERTY_GROUP_AMBASSADOR_FORM = 283;

export const AMBASSADOR_PORTAL_TERMS_AND_CONDITIONS = 324;

export const CAMPAIGN_INCENTIVE_PRODUCT = 242;
export const CAMPAIGN_INCENTIVE_OTHER = 341;

// User Discount Types
export const USER_DISCOUNT_TYPE_FIXED = 436;
export const USER_DISCOUNT_TYPE_PERCENTAGE = 437;
